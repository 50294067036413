














































































































































































































































































































import { Component, Mixins } from "vue-property-decorator";
import FeedbackComponent from "./Feedback.component";

@Component
export default class Feedback extends Mixins(FeedbackComponent) {}
