import { Component, Vue } from "vue-property-decorator";
import DatePicker from 'vue2-datepicker';
import moment from 'moment';
import Model from "@/models/Model";
import VModal from 'vue-js-modal'

import 'vue2-datepicker/index.css';
import { GET_RATEUS, GET_COMMENT } from "../../../api/account";
import { LOADING_STYLE } from '../Constants/loadingStyle'
Vue.use(VModal, { componentName: 'modal' })
@Component({
    components: {
        DatePicker
    },
    filters: {
        dateTime(val: any) {
            return val ? moment(val).local().format('DD-MM-YYYY HH:mm:ss') : ''
        }
    }
})
class RateUs extends Vue {
    $axios: any;
    private model: Model;
    public commentData: any = []
    public dateFilter = {
        startDate: moment().local().startOf('day').toDate(),
        endDate: moment().local().endOf('day').toDate()
    }

    public rate = {
        customerService: 0,
        gameplay: 0,
        graphicDesign: 0,
        networkConnection: 0,
        soundEffect: 0
    }

    public paginate = {
        page: 1,
        limit: 10
    }

    public pageSize = 0;
    public paginateShow = false;
    public dataLoading = false;

    constructor() {
        super();
        this.model = new Model();
    }

    async mounted() {
        this.addRouteName()
        this.dataLoading = true
        await this.getRateUs()
        await this.getComment(this.paginate.page, this.paginate.limit)
        this.dataLoading = false
    }

    private addRouteName(): void {
        this.model.Name = this.$route.name;
        this.$store
            .dispatch("addRouteName", this.model)
    }

    async getRateUs(): Promise<void> {
        const lists = await GET_RATEUS()
        if(lists.data){
            const rateFields = ['customerService', 'gameplay', 'graphicDesign', 'networkConnection', 'soundEffect'];
            for (const value of lists.data) {
                rateFields.forEach(field => {
                    this.rate[field] += this.convertRate(value[field])
                });
            }

            const dataLength = lists.data.length;
            if (dataLength > 0) {
                rateFields.forEach(field => {
                    this.rate[field] = this.rate[field] / dataLength;
                });
            }
        }
    }

    convertRate(num: string): number{
        return parseInt(num) ? parseInt(num) : 0
    }

    public async filterCommentData(): Promise<void> {
        this.paginate.page = 1
        await this.getComment(this.paginate.page, this.paginate.limit)
    }

    public async selectDate(option: string): Promise<void> {
        switch (option) {
            case 'option1':
                this.dateFilter.startDate = moment(this.dateFilter.startDate).subtract(1, 'days').toDate()
                this.dateFilter.endDate = moment(this.dateFilter.endDate).subtract(1, 'days').toDate()
                break;
            case 'option2':
                this.dateFilter.startDate = moment().local().startOf('day').toDate()
                this.dateFilter.endDate = moment().local().endOf('day').toDate()
                break;
            case 'option3':
                this.dateFilter.startDate = moment().local(true).startOf('day').subtract(1, 'days').toDate()
                this.dateFilter.endDate = moment().local(true).endOf('day').subtract(1, 'days').toDate()
                break;
            case 'option4':
                this.dateFilter.startDate = moment().local(true).startOf('week').toDate()
                this.dateFilter.endDate = moment().local(true).endOf('week').toDate()
                break;
            case 'option5':
                this.dateFilter.startDate = moment().local(true).subtract(1, 'week').startOf('week').toDate()
                this.dateFilter.endDate = moment().local(true).subtract(1, 'week').endOf('week').toDate()
                break;
            case 'option6':
                this.dateFilter.startDate = moment().local(true).startOf('month').toDate()
                this.dateFilter.endDate = moment().local(true).endOf('month').toDate()
                break;
            case 'option7':
                this.dateFilter.startDate = moment().local(true).subtract(1, 'months').startOf('month').toDate()
                this.dateFilter.endDate = moment().local(true).subtract(1, 'months').endOf('month').toDate()
                break;
            case 'option8':
                this.dateFilter.startDate = moment(this.dateFilter.startDate).add(1, 'days').toDate()
                this.dateFilter.endDate = moment(this.dateFilter.endDate).add(1, 'days').toDate()
                break;
            default:
                break;
        }
        await this.getComment(this.paginate.page, this.paginate.limit)
    }

    public async clickCallback(page: number): Promise<void> {
        this.paginate.page = page
        await this.getComment(this.paginate.page, this.paginate.limit)
    }

    private async getComment(page: number, limit: number): Promise<void> {
        this.commentData = []
        const loader = this.$loading.show(LOADING_STYLE);
        const lists = await GET_COMMENT((this.dateFilter.startDate).toISOString(), (this.dateFilter.endDate).toISOString(), page, limit)       
        this.commentData = lists.data[0];

        if (this.commentData.metadata.length >= 1) {
            const num = (this.paginate.page * this.paginate.limit)
            for (let i = 0, k = num; i < this.commentData.metadata.length; i++, k++) {
                this.commentData.metadata[i].no = k - this.paginate.limit
            }
            if (this.commentData.pageInfo[0].count > this.paginate.limit) {
                this.paginateShow = true
            }
            this.pageSize = this.commentData.pageInfo[0].size;
        } else {
            this.commentData = []
            this.paginateShow = false
        }
        loader.hide()
    }
}

export default RateUs